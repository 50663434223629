import { BUTTON_ANIMATIONS } from '@elo-kit/constants/animations.constants'
import { BUTTON_STYLE } from '@elo-kit/constants/contentPage.constants'

import {
  DEFAULT_TEXT_PADDING,
  DEFAULT_TEXT_PERCENT_PADDING,
  BLOCK_VIEW,
  PAYMENT_OPTION,
  BUTTON_TYPE,
  DISPLAY_OPTION,
  DEFAULT_PICTURE_PADDING,
  DEFAULT_VIDEO_WIDTH,
  DEFAULT_VIDEO_PADDING,
  DEFAULT_VIDEO_MAX_WIDTH,
  DEFAULT_FILES_PADDING,
  DEFAULT_FILE_MAX_WIDTH,
  VIDEO_TYPE,
  DEFAULT_SORTING_DIR,
  DEFAULT_SORTING_DIR_KEYS,
  DEFAULT_BANNER_PADDING,
} from 'constants/contentPageShared.constants'
import { POSITIONS, YES, NO, COLORS as GENERAL_COLORS } from '@elo-kit/constants/general.constants'
import { DEFAULT_SHOP_ITEMS_PER_PAGE } from 'constants/pagination.constants'
import { DEFAULT_LANDING_TEXT } from 'constants/affiliatePrograms.constants'

import { COLORS, FONTS, FONT_STYLES } from './pageBuilder.constants'

// Will be moved to ui kit with affiliate block
export const AFFILIATE_CLASS_NAMES = {
  containerClassName: 'cs-affiliate-container',
  buttonClassName: 'cs-affiliate-button',
  textContainerClassName: 'cs-affiliate-text-container',
}

export const HORIZONTAL_ALIGNMENT_SELECT_OPTIONS = [
  {
    label: I18n.t('react.shared.position.left'),
    value: POSITIONS.left,
  },
  {
    label: I18n.t('react.shared.position.center'),
    value: POSITIONS.center,
  },
  {
    label: I18n.t('react.shared.position.right'),
    value: POSITIONS.right,
  },
]

export const VERTICAL_ALIGNMENT_SELECT_OPTIONS = [
  {
    label: I18n.t('react.shared.position.top'),
    value: POSITIONS.top,
  },
  {
    label: I18n.t('react.shared.position.center'),
    value: POSITIONS.center,
  },
  {
    label: I18n.t('react.shared.position.bottom'),
    value: POSITIONS.bottom,
  },
]

export const YES_NO_OPTIONS = [
  {
    label: I18n.t('shared.common.yes'),
    value: YES,
  },
  {
    label: I18n.t('shared.common.no'),
    value: NO,
  },
]

export const IMAGE_STYLE_TYPES = {
  background: 'background',
  smallImage: 'smallImage',
}

export const IMAGE_STYLE_OPTIONS = [
  {
    label: I18n.t('react.shared.small_image'),
    value: IMAGE_STYLE_TYPES.smallImage,
  },
  {
    label: I18n.t('react.shared.full_width_image'),
    value: IMAGE_STYLE_TYPES.background,
  },
]

export const BAR_STYLE_TYPES = {
  loading: 'loading',
  bordered: 'bordered',
  sections: 'sections',
  circle: 'circle',
  filledCircle: 'filledCircle',
  halfCircle: 'halfCircle',
  pieCircle: 'pieCircle',
}

export const BAR_STYLE_OPTIONS = [
  {
    label: I18n.t('react.shared.loading'),
    value: BAR_STYLE_TYPES.loading,
  },
  {
    label: I18n.t('react.shared.bordered'),
    value: BAR_STYLE_TYPES.bordered,
  },
  {
    label: I18n.t('react.shared.sections'),
    value: BAR_STYLE_TYPES.sections,
  },
  {
    label: I18n.t('react.shared.circle'),
    value: BAR_STYLE_TYPES.circle,
  },
  {
    label: I18n.t('react.shared.filled_circle'),
    value: BAR_STYLE_TYPES.filledCircle,
  },
  {
    label: I18n.t('react.shared.half_circle'),
    value: BAR_STYLE_TYPES.halfCircle,
  },
  {
    label: I18n.t('react.shared.pie_circle'),
    value: BAR_STYLE_TYPES.pieCircle,
  },
]

export const COUNTDOWN_TYPES = {
  counter: 'counter',
  countdown: 'countdown',
}

export const COUNTDOWN_TYPE_SELECT_OPTIONS = [
  {
    label: I18n.t('react.shared.counter'),
    value: COUNTDOWN_TYPES.counter,
  },
  {
    label: I18n.t('react.shared.countdown'),
    value: COUNTDOWN_TYPES.countdown,
  },
]

export enum COUNTING_DIRECTIONS {
  up = 'up',
  down = 'down',
}

export const COUNTING_DIRECTION_SELECT_OPTIONS = [
  {
    label: I18n.t('react.shared.up'),
    value: COUNTING_DIRECTIONS.up,
  },
  {
    label: I18n.t('react.shared.down'),
    value: COUNTING_DIRECTIONS.down,
  },
]

export const COUNTDOWN_UNITS_TYPES = {
  months: 'months',
  weeks: 'weeks',
  days: 'days',
  hours: 'hours',
  minutes: 'minutes',
  seconds: 'seconds',
}

export const COUNTDOWN_UNITS_SELECT_OPTIONS = [
  {
    label: I18n.t('react.shared.months'),
    value: COUNTDOWN_UNITS_TYPES.months,
  },
  {
    label: I18n.t('react.shared.weeks'),
    value: COUNTDOWN_UNITS_TYPES.weeks,
  },
  {
    label: I18n.t('react.shared.days'),
    value: COUNTDOWN_UNITS_TYPES.days,
  },
  {
    label: I18n.t('react.shared.hours'),
    value: COUNTDOWN_UNITS_TYPES.hours,
  },
  {
    label: I18n.t('react.shared.minutes'),
    value: COUNTDOWN_UNITS_TYPES.minutes,
  },
  {
    label: I18n.t('react.shared.seconds'),
    value: COUNTDOWN_UNITS_TYPES.seconds,
  },
]

export const LINK_TYPE = {
  custom: 'custom',
  themePage: 'theme_page',
}

export const CATEGORIES_VIEW_TYPE = {
  tabs: 'tabs',
  dropdown: 'dropdown',
}

export const PADDING_UNITS = {
  px: 'px',
  percentage: '%',
}

export const FUNNEL_TEXT_PARAMS = {
  start_buy_link: '%{start_buy_link}',
  end_buy_link: '%{end_buy_link}',
  start_continue_link: '%{start_continue_link}',
  end_continue_link: '%{end_continue_link}',
}

export const AFFILIATE_TEXT_PARAMS = {
  program: '%{program}',
  commission: '%{commission}',
  cookies: '%{cookies}',
  buttons: '%{buttons}',
}

export const BUTTON_ANIMATION_OPTIONS = [
  {
    label: I18n.t('react.shared.animation.shadow'),
    value: BUTTON_ANIMATIONS.shadow,
  },
  {
    label: I18n.t('react.shared.default_theme'),
    value: BUTTON_ANIMATIONS.transparency,
  },
  {
    label: I18n.t('react.shared.animation.stretching'),
    value: BUTTON_ANIMATIONS.stretching,
  },
  {
    label: I18n.t('react.shared.animation.bordering'),
    value: BUTTON_ANIMATIONS.bordering,
  },
  {
    label: I18n.t('react.shared.animation.increasing'),
    value: BUTTON_ANIMATIONS.increasing,
  },
  {
    label: I18n.t('react.shared.animation.swing'),
    value: BUTTON_ANIMATIONS.swing,
  },
  {
    label: I18n.t('react.shared.animation.fill_outline_switch'),
    value: BUTTON_ANIMATIONS.fillOutlineSwitch,
  },
  {
    label: I18n.t('react.shared.animation.colored_shadow'),
    value: BUTTON_ANIMATIONS.coloredShadow,
  },
  {
    label: I18n.t('react.shared.animation.no_animation'),
    value: BUTTON_ANIMATIONS.noAnimation,
  },
]

export const BUTTON_STYLE_OPTIONS = [
  {
    label: I18n.t('react.shared.button_style.filled'),
    value: BUTTON_STYLE.filled,
  },
  {
    label: I18n.t('react.shared.button_style.outlined'),
    value: BUTTON_STYLE.outlined,
  },
]

export const BUTTON_OPEN_LINK_OPTIONS = [
  {
    label: I18n.t('shared.common.yes'),
    value: 'yes',
  },
  {
    label: I18n.t('shared.common.no'),
    value: 'no',
  },
]

export const SHADOW_OPTIONS = [
  {
    label: I18n.t('shared.common.yes'),
    value: 'yes',
  },
  {
    label: I18n.t('shared.common.no'),
    value: 'no',
  },
]

export const AUDIO_TYPES = {
  default: 'default',
  custom: 'custom',
}

export const AUDIO_PLAYER_OPTIONS = [0.5, 0.75, 1, 1.25, 1.5, 2]
export const AUDIO_PLAYER_Z_INDEX = 21

export const MAX_CORNER_RADIUS = 100
export const MAX_PERCENTAGE = 100
export const MAX_PIXELS = 9999

export const BLOCK_MENU_HIGHLIGHT_IDS = {
  text: {
    background: 'background',
    text: 'text',
  },
  button: {
    background: 'background',
    buttonFunctionality: 'buttonFunctionality',
  },
  picture: {
    background: 'background',
    image: 'image',
  },
  audio_player: {
    background: 'background',
    playerBackground: 'player_background',
    image: 'image',
    mainText: 'mainText',
    additionalText: 'additional_text',
  },
  thankYouPage: {
    background: 'background',
    header: 'header',
    mainButton: 'main_button',
    secondaryButton: 'secondary_button',
    title: 'title',
    labels: 'labels',
    details: 'details',
    price: 'price',
  },
}
// prettier-ignore
const DEFAULT_TEXT_FOR_TEXT_BLOCK = `<p style=\"font-family: Montserrat Med; font-size: 24px;\">${I18n.t('react.shared.here_goes_your_title')}<br></p><br><p><span style=\"font-family: Montserrat Med; font-size: 15px;\">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</span></p> `

export const BLOCK_DEFAULT_VALUES = {
  text: {
    backgroundColor: COLORS.eloWhite,
    transparency: 1,
    width: 100,
    maxWidth: 5000,
    paddingUnits: PADDING_UNITS.percentage,
    text: DEFAULT_TEXT_FOR_TEXT_BLOCK,
    ...DEFAULT_TEXT_PERCENT_PADDING,
  },
  picture: {
    width: MAX_PERCENTAGE,
    maxWidth: MAX_PERCENTAGE,
    backgroundColor: COLORS.eloWhite,
    paddingTop: DEFAULT_PICTURE_PADDING,
    paddingBottom: DEFAULT_PICTURE_PADDING,
    paddingRight: DEFAULT_PICTURE_PADDING,
    paddingLeft: DEFAULT_PICTURE_PADDING,
    horizontalAlignment: POSITIONS.center,
    verticalAlignment: POSITIONS.center,
    paddingUnits: PADDING_UNITS.px,
  },
  video: {
    playerColor: COLORS.eloOrange,
    transparency: 0,
    playbarDisabled: false,
    backgroundColor: COLORS.eloWhite,
    paddingTop: DEFAULT_VIDEO_PADDING,
    paddingBottom: DEFAULT_VIDEO_PADDING,
    paddingRight: DEFAULT_VIDEO_PADDING,
    paddingLeft: DEFAULT_VIDEO_PADDING,
    horizontalAlignment: POSITIONS.center,
    width: DEFAULT_VIDEO_WIDTH,
    maxWidth: DEFAULT_VIDEO_MAX_WIDTH,
    integrationsBackground: COLORS.eloWhite,
    paddingUnits: PADDING_UNITS.px,
    type: VIDEO_TYPE.elopage,
  },
  file: {
    width: MAX_PERCENTAGE,
    maxWidth: DEFAULT_FILE_MAX_WIDTH,
    transparency: 0,
    backgroundColor: COLORS.eloWhite,
    paddingTop: DEFAULT_FILES_PADDING,
    paddingBottom: DEFAULT_FILES_PADDING,
    paddingRight: DEFAULT_FILES_PADDING,
    paddingLeft: DEFAULT_FILES_PADDING,
    buttonTextColor: COLORS.eloBlue,
    buttonColor: COLORS.eloBlue,
    buttonFont: FONTS.medium,
    buttonStyle: FONT_STYLES.normal,
    buttonSize: 12,
    buttonViewStyle: BUTTON_STYLE_OPTIONS[1].value,
    buttonAnimation: BUTTON_ANIMATIONS.transparency,
    radius: 17,
    paddingUnits: PADDING_UNITS.px,
    textFont: FONTS.medium,
    textStyle: FONT_STYLES.normal,
    textColor: COLORS.eloBlack,
    textSize: 13,
  },
  file_pdf: {
    backgroundColor: COLORS.eloGrey40,
    transparency: 1,
    buttonColor: COLORS.eloGrey60,
    toolbarButtonSize: 16,
    toolbarBackgroundColor: COLORS.eloWhite,
    toolbarFontSize: 12,
    toolbarFont: FONTS.bold,
    toolbarFontStyle: FONT_STYLES.normal,
    toolbarColor: COLORS.eloGrey60,
    progressBackgroundColor: COLORS.eloBlue,
    downloadFile: DISPLAY_OPTION.display,
  },
  seller_products: {
    backgroundColor: COLORS.eloWhite,
    transparency: 0,
    productBackground: COLORS.eloWhite,
    productSize: 18,
    productStyle: FONT_STYLES.normal,
    productFont: FONTS.bold,
    productColor: COLORS.eloBlack,
    priceFont: FONTS.bold,
    priceStyle: FONT_STYLES.normal,
    priceColor: COLORS.eloWhite,
    priceBackground: COLORS.eloBlue,
    priceSize: 20,
    view: BLOCK_VIEW.grid,
    buttonText: I18n.t('shared.common.buy_now'),
    buttonFont: FONTS.bold,
    buttonStyle: FONT_STYLES.normal,
    buttonSize: 16,
    buttonColor: COLORS.eloBlue,
    buttonTextColor: COLORS.eloWhite,
    descriptionFont: FONTS.medium,
    descriptionStyle: FONT_STYLES.normal,
    descriptionColor: COLORS.eloBlack,
    descriptionSize: 14,
    currencyFont: FONTS.bold,
    currencyStyle: FONT_STYLES.normal,
    currencyColor: '#808080',
    currencySize: 30,
    oldPriceFont: FONTS.bold,
    oldPriceStyle: FONT_STYLES.normal,
    oldPriceColor: '#787878',
    oldPriceSize: 14,
    categoryFont: FONTS.bold,
    categoryStyle: FONT_STYLES.normal,
    categoryColor: COLORS.eloBlue,
    categoryBackground: COLORS.eloWhite,
    categoryActiveColor: COLORS.eloBlack,
    categorySize: 15,
    categoryDescriptionFont: FONTS.medium,
    categoryDescriptionStyle: FONT_STYLES.normal,
    categoryDescriptionColor: COLORS.eloBlack,
    categoryDescriptionSize: 14,
    categoriesView: CATEGORIES_VIEW_TYPE.tabs,
    searchFont: FONTS.medium,
    searchStyle: FONT_STYLES.normal,
    searchColor: COLORS.eloBlue,
    searchBackground: COLORS.eloWhite,
    searchActiveColor: COLORS.eloBlue,
    searchSize: 13,
    buttonViewStyle: BUTTON_STYLE_OPTIONS[0].value,
    radius: 4,
    buttonAnimation: BUTTON_ANIMATIONS.transparency,
    itemsPerPage: DEFAULT_SHOP_ITEMS_PER_PAGE,
    showAllProducts: true,
    sortKey: DEFAULT_SORTING_DIR_KEYS.position,
    sortDir: DEFAULT_SORTING_DIR.position,
  },
  testimonials: {
    arrowsColor: COLORS.eloWhite,
    textFont: FONTS.medium,
    textStyle: 'italic',
    textColor: '#787878',
    textSize: 16,
    usernamesFont: FONTS.medium,
    usernamesColor: '#787878',
    usernamesSize: 30,
    backgroundColor: COLORS.eloGrey20,
    transparency: 0,
    testimonials: [],
  },
  popular_products: {
    textFont: FONTS.bold,
    textColor: COLORS.eloBlack,
    textSize: 20,
    backgroundColor: COLORS.eloGrey20,
    transparency: 0,
    buttonColor: COLORS.eloBlue,
    buttonFont: FONTS.bold,
    buttonSize: 16,
    buttonStyle: FONT_STYLES.normal,
    buttonTextColor: COLORS.eloWhite,
    productBackground: COLORS.eloWhite,
    arrowsColor: COLORS.eloWhite,
    autobuyConfirmButtonColor: COLORS.eloBlue,
    autobuyCancelButtonColor: 'transparent',
    autobuyConfirmButtonTextColor: COLORS.eloWhite,
    autobuyCancelButtonTextColor: COLORS.eloWhite,
    autobuyModalTitleColor: COLORS.eloBlack,
    view: BLOCK_VIEW.slider,
    buttonText: I18n.t('shared.common.buy_now'),
    freeButtonText: I18n.t('shared.common.buy_now'),
    buttonViewStyle: BUTTON_STYLE_OPTIONS[0].value,
    radius: 4,
    buttonAnimation: BUTTON_ANIMATIONS.shadow,
    descriptionFont: FONTS.medium,
    descriptionStyle: FONT_STYLES.normal,
    descriptionColor: COLORS.eloBlack,
    descriptionSize: 14,
    currencyFont: FONTS.bold,
    currencyStyle: FONT_STYLES.normal,
    currencyColor: '#808080',
    currencySize: 30,
    oldPriceFont: FONTS.bold,
    oldPriceStyle: FONT_STYLES.normal,
    oldPriceColor: '#787878',
    oldPriceSize: 14,
    priceFont: FONTS.bold,
    priceStyle: FONT_STYLES.bold,
    priceColor: COLORS.eloBlack,
    priceSize: 20,
    priceRadius: 4,
    buttonType: BUTTON_TYPE.buy,
    paymentOption: PAYMENT_OPTION.autopay,
    searchFont: FONTS.medium,
    searchStyle: FONT_STYLES.normal,
    searchColor: COLORS.eloBlue,
    searchBackground: COLORS.eloWhite,
    searchActiveColor: COLORS.eloBlue,
    searchSize: 13,
    priceBackground: COLORS.eloBlue,
    showPrice: false,
    itemsPerSlide: '3',
  },
  header: {
    backgroundColor: COLORS.eloWhite,
    menuFont: FONTS.bold,
    menuColor: COLORS.eloBlue,
    menuSize: 15,
    socialsColor: COLORS.eloBlue,
    accountFont: FONTS.medium,
    accountColor: COLORS.eloBlue,
    accountSize: 15,
    iconColor: COLORS.eloBlue,
    languageFont: FONTS.bold,
    languageColor: COLORS.eloBlue,
    languageSize: 15,
    sharingOptionsColor: COLORS.eloBlue,
    myAccountSwitcher: true,
    stickyHeader: false,
    showCancelSubscription: true,
  },
  banner: {
    backgroundColor: COLORS.eloWhite,
    transparency: 0,
    mainColor: COLORS.eloBlack,
    mainSize: 60,
    mainFont: FONTS.bold,
    additionalColor: COLORS.eloBlack,
    additionalSize: 16,
    additionalFont: FONTS.medium,
    buttonText: I18n.t('react.shared.button.uppercased'),
    buttonTextColor: COLORS.eloWhite,
    buttonColor: COLORS.eloBlue,
    buttonFont: FONTS.bold,
    buttonStyle: FONT_STYLES.bold,
    buttonSize: 15,
    showButton: true,
    titleText: I18n.t('react.cabinet.here_goes_your_slogan'),
    additionalText: I18n.t('react.cabinet.here_goes_short_description'),
    horizontalAlignment: POSITIONS.left,
    buttonViewStyle: BUTTON_STYLE_OPTIONS[0].value,
    radius: 4,
    buttonAnimation: BUTTON_ANIMATIONS.transparency,
    textAlignment: POSITIONS.left,
    width: 100,
    maxWidth: 500,
    paddingTop: DEFAULT_BANNER_PADDING,
    paddingBottom: DEFAULT_BANNER_PADDING,
    paddingRight: DEFAULT_BANNER_PADDING,
    paddingLeft: DEFAULT_BANNER_PADDING,
    paddingUnits: PADDING_UNITS.px,
  },
  footer: {
    backgroundColor: COLORS.eloWhite,
    menuFont: FONTS.bold,
    menuColor: '#9e9e9e',
    menuSize: 15,
    submenuFont: FONTS.medium,
    socialsColor: COLORS.eloBlue,
    submenuColor: COLORS.eloBlue,
    submenuSize: 13,
  },
  membership_products: {
    backgroundColor: COLORS.eloWhite,
    productBackground: COLORS.eloWhite,
    productSize: 18,
    productFont: FONTS.bold,
    productColor: COLORS.eloBlack,
    progressBackground: COLORS.eloGrey20,
    progressColor: COLORS.eloBlue,
    view: BLOCK_VIEW.grid,
    descriptionFont: FONTS.medium,
    descriptionStyle: FONT_STYLES.normal,
    descriptionColor: COLORS.eloBlack,
    descriptionSize: 14,
    searchFont: FONTS.medium,
    searchStyle: FONT_STYLES.normal,
    searchColor: COLORS.eloBlue,
    searchBackground: COLORS.eloWhite,
    categoriesView: CATEGORIES_VIEW_TYPE.tabs,
    categoryFont: FONTS.bold,
    categoryStyle: FONT_STYLES.normal,
    categoryColor: COLORS.eloBlue,
    categoryBackground: COLORS.eloWhite,
    categoryActiveColor: COLORS.eloBlack,
    categorySize: 15,
    categoryDescriptionFont: FONTS.medium,
    categoryDescriptionStyle: FONT_STYLES.normal,
    categoryDescriptionColor: COLORS.eloBlack,
    categoryDescriptionSize: 14,
    searchActiveColor: COLORS.eloBlue,
    searchSize: 13,
    showAllProducts: true,
  },
  product: {
    arrowsColor: COLORS.eloOrange,
    arrowFontSize: 40,
    background: COLORS.eloWhite,
    textFont: FONTS.bold,
    textStyle: FONT_STYLES.bold,
    textColor: COLORS.eloBlack,
    textSize: 40,
    backButtonFont: FONTS.bold,
    backButtonStyle: FONT_STYLES.normal,
    backButtonColor: COLORS.eloBlue,
    backButtonSize: 15,
    priceFont: FONTS.bold,
    priceStyle: FONT_STYLES.bold,
    priceColor: COLORS.eloBlack,
    priceSize: 70,
    radius: 4,
    oldPriceFont: FONTS.bold,
    oldPriceStyle: FONT_STYLES.bold,
    oldPriceColor: '#9e9e9e',
    oldPriceSize: 18,
    buttonFont: FONTS.bold,
    buttonStyle: FONT_STYLES.normal,
    buttonTextColor: COLORS.eloWhite,
    buttonColor: COLORS.eloBlue,
    buttonSize: 15,
    planFont: FONTS.bold,
    planStyle: FONT_STYLES.normal,
    planColor: COLORS.eloBlack,
    planSize: 14,
    planTitleFont: FONTS.bold,
    planTitleStyle: FONT_STYLES.normal,
    planTitleColor: COLORS.eloBlack,
    planTitleSize: 14,
    currencyFont: FONTS.medium,
    currencyStyle: FONT_STYLES.bold,
    currencyColor: '#808080',
    currencySize: 40,
    payInfoFont: FONTS.medium,
    payInfoStyle: FONT_STYLES.normal,
    payInfoColor: '#9e9e9e',
    payInfoSize: 14,
    previewButtonFont: FONTS.medium,
    previewButtonStyle: FONT_STYLES.normal,
    previewButtonTextColor: COLORS.eloWhite,
    previewButtonColor: COLORS.eloBlue,
    previewButtonSize: 13,
    coursePreview: false,
    ticketsPreview: false,
    downloadPreview: false,
    ticketsDetailsFont: FONTS.bold,
    ticketsDetailsStyle: FONT_STYLES.normal,
    ticketsDetailsColor: COLORS.eloBlack,
    ticketsDetailsSize: 14,
    ticketsTitleFont: FONTS.bold,
    ticketsTitleStyle: FONT_STYLES.normal,
    ticketsTitleColor: COLORS.eloBlack,
    ticketsTitleSize: 24,
    downloadDetailsFont: FONTS.medium,
    downloadDetailsStyle: FONT_STYLES.normal,
    downloadDetailsColor: COLORS.eloBlack,
    downloadDetailsSize: 14,
    downloadTitleFont: FONTS.bold,
    downloadTitleStyle: FONT_STYLES.normal,
    downloadTitleColor: COLORS.eloBlack,
    downloadTitleSize: 24,
  },
  button: {
    backgroundColor: COLORS.eloWhite,
    transparency: 0,
    buttonColor: COLORS.eloBlue,
    buttonViewStyle: BUTTON_STYLE_OPTIONS[0].value,
    radius: 30,
    horizontalAlignment: POSITIONS.center,
    buttonText: I18n.t('react.shared.button.buy_now_uppercase'),
    buttonFont: FONTS.bold,
    buttonStyle: FONT_STYLES.bold,
    buttonTextColor: COLORS.eloWhite,
    buttonSize: 20,
    buttonType: BUTTON_TYPE.customLink,
    buttonOpenInCurrentLink: '',
    paymentOptions: PAYMENT_OPTION.autopay,
    priceFont: FONTS.bold,
    priceStyle: FONT_STYLES.bold,
    priceColor: COLORS.eloBlack,
    buttonAnimation: BUTTON_ANIMATIONS.shadow,
    buttonWidth: 170,
    minHeight: 0,
  },
  funnel: {
    type: NO,
    view: BLOCK_VIEW.button,
    backgroundColor: COLORS.eloWhite,
    transparency: 0,
    buttonColor: COLORS.eloBlue,
    buttonViewStyle: BUTTON_STYLE_OPTIONS[1].value,
    radius: 4,
    horizontalAlignment: POSITIONS.center,
    buttonText: I18n.t('react.shared.funnels.no_thanks_uppercased'),
    buttonFont: FONTS.bold,
    buttonStyle: FONT_STYLES.bold,
    buttonTextColor: COLORS.eloBlue,
    buttonSize: 15,
    buttonAnimation: BUTTON_ANIMATIONS.shadow,
    buttonWidth: 240,
    minHeight: 0,
    paddingTop: DEFAULT_TEXT_PADDING,
    paddingBottom: DEFAULT_TEXT_PADDING,
    paddingRight: DEFAULT_TEXT_PADDING,
    paddingLeft: DEFAULT_TEXT_PADDING,
    width: 100,
    maxWidth: 5000,
    paddingUnits: PADDING_UNITS.px,
  },
  audio_player: {
    backgroundColor: COLORS.eloGrey20,
    playerBackgroundColor: COLORS.eloWhite,
    transparency: 0,
    buttonColor: COLORS.eloBlue,
    paddingTop: 1,
    paddingBottom: 1,
    paddingRight: 20,
    paddingLeft: 20,
    width: 100,
    maxWidth: 5000,
    shadow: YES,
    paddingUnits: PADDING_UNITS.percentage,
    mainColor: COLORS.eloBlack,
    mainSize: 16,
    mainFont: FONTS.bold,
    mainStyle: FONT_STYLES.bold,
    additionalColor: COLORS.eloBlack,
    additionalSize: 14,
    additionalFont: FONTS.medium,
    additionalStyle: FONT_STYLES.normal,
    titleText: I18n.t('react.cabinet.audio_player.artist_name'),
    additionalText: I18n.t('react.cabinet.audio_player.track_name'),
    horizontalAlignment: POSITIONS.center,
    verticalAlignment: POSITIONS.center,
    textAlignment: POSITIONS.left,
    progressColor: COLORS.eloBlue,
    audioView: AUDIO_TYPES.custom,
    radius: 8,
  },
  affiliate: {
    view: BLOCK_VIEW.text,
    backgroundColor: COLORS.eloWhite,
    transparency: 0,
    buttonColor: COLORS.eloBlue,
    buttonViewStyle: BUTTON_STYLE_OPTIONS[0].value,
    radius: 4,
    horizontalAlignment: POSITIONS.center,
    buttonFont: FONTS.bold,
    buttonStyle: FONT_STYLES.normal,
    buttonTextColor: COLORS.eloWhite,
    buttonText: I18n.t('react.shared.button.accept'),
    buttonSize: 15,
    buttonAnimation: BUTTON_ANIMATIONS.transparency,
    buttonWidth: 170,
    minHeight: 70,
    paddingTop: 80,
    paddingBottom: 80,
    paddingRight: DEFAULT_TEXT_PADDING,
    paddingLeft: DEFAULT_TEXT_PADDING,
    width: 100,
    maxWidth: 5000,
    paddingUnits: PADDING_UNITS.px,
    text: DEFAULT_LANDING_TEXT,
  },
  contact_form: {
    formColor: COLORS.eloWhite,
    formName: I18n.t('react.shared.contact_form_default_name'),
    backgroundColor: COLORS.eloGrey20,
    transparency: 0,
    inputsBorderColor: COLORS.eloGrey40,
    inputsHeight: 44,
    radius: 0,
    shadow: YES,
    mainSize: 24,
    mainColor: COLORS.eloBlack,
    mainFont: FONTS.bold,
    mainStyle: FONT_STYLES.bold,
    descriptionSize: 13,
    descriptionColor: COLORS.eloGrey70,
    descriptionFont: FONTS.medium,
    descriptionStyle: FONT_STYLES.normal,
    labelsSize: 14,
    labelsColor: COLORS.eloGrey60,
    labelsFont: FONTS.bold,
    labelsStyle: FONT_STYLES.normal,
    placeholdersSize: 14,
    placeholdersColor: COLORS.eloGrey40,
    placeholdersFont: FONTS.medium,
    placeholdersStyle: FONT_STYLES.normal,
    buttonTextColor: COLORS.eloWhite,
    buttonColor: COLORS.eloBlue,
    buttonFont: FONTS.medium,
    buttonStyle: FONT_STYLES.bold,
    buttonSize: 16,
    buttonViewStyle: BUTTON_STYLE_OPTIONS[0].value,
    buttonAnimation: BUTTON_ANIMATIONS.transparency,
    buttonRadius: 0,
    formRadius: 0,
    buttonText: I18n.t('react.shared.button.continue'),
    formTransparency: 0,
    buttonWidth: 420,
    paddingTop: 40,
    paddingBottom: 40,
    paddingRight: 40,
    paddingLeft: 40,
    paddingUnits: PADDING_UNITS.px,
    width: 60,
    maxWidth: 500,
    titleText: I18n.t('react.shared.contact_form_block_title'),
    descriptionText: I18n.t('react.shared.contact_form_description'),
  },
  conversion_tools: {
    view: BLOCK_VIEW.countdown,
    backgroundColor: COLORS.eloGrey20,
    iconAlignment: POSITIONS.center,
    transparency: 0,
    radius: 0,
    width: 95,
    maxWidth: 730,
    paddingTop: 40,
    paddingBottom: 40,
    paddingRight: 60,
    paddingLeft: 60,
    paddingUnits: PADDING_UNITS.px,
    numbersFontSize: 50,
    numbersFont: FONTS.bolder,
    numbersFontStyle: FONT_STYLES.bold,
    numbersColor: GENERAL_COLORS.eloGrey70,
    textFont: FONTS.bold,
    textFontStyle: FONT_STYLES.normal,
    textFontSize: 13,
    textColor: COLORS.eloGrey60,
    countdownType: COUNTDOWN_TYPES.counter,
    countdownStep: 1,
    countdownUnits: 'days',
    countdownNumber: 100,
    countdownItem: '%',
    countdownStepUnits: COUNTDOWN_UNITS_TYPES.days,
    additionalFont: FONTS.medium,
    additionalStyle: FONT_STYLES.normal,
    additionalSize: 16,
    additionalColor: COLORS.eloBlack,
    mainFont: 'Montserrat Black',
    mainStyle: FONT_STYLES.normal,
    mainColor: COLORS.eloBlack,
    mainSize: 44,
    buttonText: I18n.t('react.shared.button.learn_more'),
    countingDirection: COUNTING_DIRECTIONS.up,
    progressBarStyle: BAR_STYLE_TYPES.loading,
    progressBarMainColor: COLORS.eloBlue,
    progressBarAdditionalColor: '#B0D6F6',
    progressBarWidth: 400,
    progressBarHeight: 30,
    showNumbers: YES,
    titleText: I18n.t('react.cabinet.here_goes_your_slogan'),
    additionalText: I18n.t('react.shared.here_goes_the_description'),
    openModalAfter: 0,
    buttonTextColor: COLORS.eloWhite,
    buttonColor: COLORS.eloBlue,
    buttonSize: 15,
    buttonViewStyle: BUTTON_STYLE_OPTIONS[0].value,
    buttonFont: FONTS.medium,
    buttonStyle: FONT_STYLES.bold,
    horizontalAlignment: POSITIONS.left,
    buttonAnimation: BUTTON_ANIMATIONS.transparency,
    countdownTransparency: 1,
    countdownBackgroundColor: COLORS.eloWhite,
    text: I18n.t('react.shared.here_goes_the_description'),
    showButton: true,
    textMaxWidth: 350,
    textWidth: 100,
    textAlignment: POSITIONS.left,
    progressBarDescriptionText: I18n.t('react.cabinet.progress_bar.description'),
    progressBarDescriptionColor: COLORS.eloBlack,
    progressBarDescriptionSize: 13,
    progressBarDescriptionFont: FONTS.medium,
    progressBarDescriptionStyle: FONT_STYLES.normal,
    circleWidth: 100,
    progressBarNumberColor: COLORS.eloBlue,
    progressBarValue: 75,
    shadow: YES,
    modalBackgroundColor: COLORS.eloWhite,
    modalTransparency: 0,
    modalPaddingTop: 53,
    modalPaddingBottom: 60,
    modalPaddingRight: 58,
    modalPaddingLeft: 58,
    imageStyle: IMAGE_STYLE_TYPES.smallImage,
  },
  thank_you_page: {
    backgroundColor: COLORS.eloWhite,
    width: 100,
    maxWidth: 1200,
    headerFont: FONTS.bold,
    headerStyle: FONT_STYLES.bold,
    headerColor: COLORS.eloBlack,
    headerText: I18n.t('react.shared.thank_you_for_your_order'),
    productNameFont: FONTS.bold,
    productNameStyle: FONT_STYLES.normal,
    productNameColor: COLORS.eloBlack,
    labelsFont: FONTS.bold,
    labelsColor: '#9e9e9e',
    labelsStyle: FONT_STYLES.normal,
    additionalTextFont: FONTS.medium,
    additionalTextColor: '#9e9e9e',
    additionalTextStyle: FONT_STYLES.normal,
    priceFont: FONTS.bold,
    priceStyle: FONT_STYLES.bold,
    priceColor: '#545454',
    buttonTextColor: COLORS.eloWhite,
    buttonColor: COLORS.eloBlue,
    buttonFont: FONTS.medium,
    buttonStyle: FONT_STYLES.normal,
    buttonAnimation: BUTTON_ANIMATIONS.transparency,
    radius: 4,
    secondaryButtonColor: COLORS.eloBlue,
    secondaryButtonFont: FONTS.medium,
    secondaryButtonStyle: FONT_STYLES.normal,
    secondaryCornerRadius: 4,
    buttonViewStyle: BUTTON_STYLE_OPTIONS[0].value,
  },
  gap: {
    backgroundColor: COLORS.eloWhite,
    transparency: 0,
    paddingTop: DEFAULT_TEXT_PADDING,
    paddingBottom: DEFAULT_TEXT_PADDING,
    paddingRight: DEFAULT_TEXT_PADDING,
    paddingLeft: DEFAULT_TEXT_PADDING,
    width: 100,
    maxWidth: 5000,
    minHeight: 300,
    paddingUnits: PADDING_UNITS.px,
  },
}

export const BLOCKS_WITH_COLOR_PICKER = {
  mainColor: 'mainColor',
  additionalColor: 'additionalColor',
  productColor: 'productColor',
  productBackground: 'productBackground',
  descriptionColor: 'descriptionColor',
  categoryActiveColor: 'categoryActiveColor',
  categoryColor: 'categoryColor',
  categoryDescriptionColor: 'categoryDescriptionColor',
  progressColor: 'progressColor',
  progressBackground: 'progressBackground',
  searchBackground: 'searchBackground',
  searchActiveColor: 'searchActiveColor',
  searchColor: 'searchColor',
  priceBackground: 'priceBackground',
  priceColor: 'priceColor',
  currencyColor: 'currencyColor',
  oldPriceColor: 'oldPriceColor',
  categoryBackground: 'categoryBackground',
  arrowsColor: 'arrowsColor',
  textColor: 'textColor',
  integrationsBackground: 'integrationsBackground',
  playerColor: 'playerColor',
  usernamesColor: 'usernamesColor',
  menuColor: 'menuColor',
  accountColor: 'accountColor',
  iconColor: 'iconColor',
  socialsColor: 'socialsColor',
  submenuColor: 'submenuColor',
  background: 'background',
  arrowColor: 'arrowColor',
  backButtonColor: 'backButtonColor',
  payInfoColor: 'payInfoColor',
  planTitleColor: 'planTitleColor',
  planColor: 'planColor',
  previewButtonColor: 'previewButtonColor',
  previewButtonTextColor: 'previewButtonTextColor',
  ticketsTitleColor: 'ticketsTitleColor',
  ticketsDetailsColor: 'ticketsDetailsColor',
  downloadTitleColor: 'downloadTitleColor',
  downloadDetailsColor: 'downloadDetailsColor',
  backgroundColor: 'backgroundColor',
  buttonTextColor: 'buttonTextColor',
  buttonColor: 'buttonColor',
  languageColor: 'languageColor',
  sharingOptionsColor: 'sharingOptionsColor',
}
